<template>
  <PatientPage :patient-no="patientNo" page-title="Settings">
    <template v-if="!isDeviceSupported" #right-content>
      <WarningBox data-testid="start-new-prescription-unavailable">
        <strong>Remember:</strong> Some settings are only accessible from the
        iPad provided by Novo Nordisk.
      </WarningBox>
    </template>
    <div class="settings-sub-page">
      <SettingsNavigation :patient-no="patientNo" />
      <router-view />
    </div>
  </PatientPage>
</template>

<script>
import PatientPage from '@/components/patient/PatientPage'
import SettingsNavigation from '@/views/patient/settings/SettingsNavigation'
import deviceInfo from '@/utils/deviceInfo'

export default {
  name: 'Settings',
  components: {
    PatientPage,
    SettingsNavigation,
    WarningBox: () => import('@/components/WarningBox.vue'),
  },
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      isDeviceSupported: false,
    }
  },
  created() {
    this.isDeviceSupported = deviceInfo.isIpadAir2019()
  },
}
</script>
