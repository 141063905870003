
import Vue from 'vue'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'

export default Vue.extend({
  name: 'SettingsNavigation',
  mixins: [selectedPatientGettersMixin],
  props: { patientNo: { type: String, required: true } },
  computed: {
    selectedPatientState() {
      return this.selectedPatientConfiguration(this.patientNo).patientState
        ?.currentState
    },
    isPatientCompleted() {
      return (
        this.selectedPatientState === patientConfigurationPatientState.COMPLETED
      )
    },
  },
})
